var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { checkDevice } from "@pernod-ricard-global-cms/jsutils";
import lazyloader from "@pernod-ricard-global-cms/cbllazyloader";
import jsAssets from "Assets/js/modules/jsAssets";
import checkBrowserInfo from "Assets/js/modules/check-browser-info";
import colourPalettes from "@pernod-ricard-global-cms/cblcolourpalettes";
import siteHeaderJs from "Assets/js/components/site-header-items-center";
import { ttfb } from "Assets/js/modules/perf-tests";
lazyloader.options.filePath = "js/blocks/";
lazyloader.options.assetArray = jsAssets.dynamicAssets;
let runLazyLoad = () => {
    lazyloader.lazyloaderInit();
};
function setupColours() {
    globalThis.requestAnimationFrame(() => {
        const colourData = globalThis.colourconfig;
        colourPalettes(colourData);
    });
}
function headerJs() {
    const header = document.querySelector(".main-site-header");
    siteHeaderJs({ block: header });
}
function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}
window.addEventListener("DOMContentLoaded", () => {
    function runSiteJs() {
        return __awaiter(this, void 0, void 0, function* () {
            // Create an array of functions to run:
            const tasks = [
                checkDevice,
                ttfb,
                checkBrowserInfo,
                setupColours,
                runLazyLoad,
                headerJs,
            ];
            // Loop over the tasks:
            while (tasks.length > 0) {
                // Shift the first task off the tasks array:
                const task = tasks.shift();
                // Run the task:
                task();
                // Yield to the main thread:
                yield yieldToMain();
            }
        });
    }
    runSiteJs();
    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--screen-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    // Function to set a custom property with the actual viewport height.
    function setVH() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    // Initial set.
    setVH();
    // Update the value when the window is resized.
    window.addEventListener('resize', () => {
        setVH();
    });
});
