import {resizeDebouncer} from '@pernod-ricard-global-cms/jsutils';
const TABLET_BREAKPOINT = 768;

function isTabletViewport() {
  return window.innerWidth <= TABLET_BREAKPOINT;
}

function calculateHeaderHeight(block) {
	const headerHeight = block.offsetHeight;
	document.documentElement.style.setProperty(
		'--global-site-header-height',
		`${headerHeight}px`
	);
}


export default function siteHeaderJs(options = {}) {
  try {
    const {block} = options;

    const $hamburger = document.getElementById('hamburger');
    const $navBar = document.getElementById('nav__bar');
    const $navMenu = document.getElementById('nav__menu');
    const $mainContainer = document.getElementById('main-container');

    function isPageScrolled() {
      return window.scrollY > $navBar.parentNode.parentNode.clientHeight;
    }

    function openClose() {
      $hamburger.classList.toggle('is-active'); // the class is used by hamburgers
      $mainContainer.classList.toggle('main-container--active');

      const isOpen = $navMenu.classList.contains('nav__menu--slide-in');
      if (isOpen) {
        $navBar.classList.remove('nav__bar--fade-in');
        $navMenu.setAttribute('class', 'nav__menu nav__menu--slide-out');
        $hamburger.setAttribute('aria-expanded', 'false');
        return;
      }
      $hamburger.setAttribute('aria-expanded', 'true');
      $navBar.classList.add('nav__bar--fade-in');
      $navMenu.setAttribute('class', 'nav__menu nav__menu--slide-in');
    }

    function initializeMobileMenu() {
      // open close menu
      if ($hamburger !== null) {
        $hamburger.addEventListener('click', () => {
          openClose();
        });
      }

      // closes menu when click on any link
      const $navLinks = $navMenu.querySelectorAll('a');
      $navLinks.forEach((aLink) => {
        aLink.addEventListener('click', () => {
          openClose();
        });
      });

      // ... other mobile-specific initializations ...
    }

    function initializeDesktopMenu() {
      // ... desktop-specific initializations ...

      // it manages hover/mouseenter on first level navigation item (desktop)
      // it triggers the modal
      const $navDropdown = document.querySelectorAll('.nav__dropdown');
      const $modalMenu = document.querySelectorAll('.modal-menu')[0];
      $navDropdown.forEach((item) => {
        item.addEventListener('mouseenter', () => {
          $modalMenu.classList.add('modal-menu--active');
          $navBar.classList.add('nav__bar--fade-in-scroll');
        });
        item.addEventListener('mouseleave', () => {
          $modalMenu.classList.remove('modal-menu--active');
          if (!isPageScrolled()) {
            $navBar.classList.remove('nav__bar--fade-in-scroll');
          }
        });
      });

      // get Max Height of first level navigation title
      const getMaxHeight = ($dropdown) => {
        let maxHeight = 0;
        const $spans = $dropdown.querySelectorAll('.nav__l1 li > span');
        if ($spans.length > 0) {
          $spans.forEach(($span) => {
            if ($span.offsetHeight > maxHeight) {
              maxHeight = $span.offsetHeight;
            }
          });
        }
        return maxHeight;
      };

      // set minHeight of first level navigation title
      const setMaxHeight = ($dropdown, mixHeight = 'auto') => {
        const $spans = $dropdown.querySelectorAll('.nav__l1 li > span');
        if ($spans.length > 0) {
          $spans.forEach(($span) => {
            $span.style.minHeight = `${mixHeight}px`;
          });
        }
      };

      // get dropdown elements from the menu and set minHeight of the first level title
      const menuTitleItems = document.querySelectorAll('.nav__top-menu .nav__dropdown');
      if (menuTitleItems.length > 0) {
        menuTitleItems.forEach(($li) => {
          setMaxHeight($li, getMaxHeight($li));
        });
      }
    }

    if (isTabletViewport()) {
      initializeMobileMenu();
    } else {
      initializeDesktopMenu();
    }

    let currentViewport = isTabletViewport() ? 'mobile' : 'desktop';

    window.addEventListener('resize', () => {
      if (isTabletViewport() && currentViewport === 'desktop') {
        // Changed from desktop to mobile
        initializeMobileMenu();
        currentViewport = 'mobile';
      } else if (!isTabletViewport() && currentViewport === 'mobile') {
        // Changed from mobile to desktop
        initializeDesktopMenu();
        currentViewport = 'desktop';
      }
    });

    // it changes opacity when the page is scrolled
    window.addEventListener('scroll', () => {
      if (isPageScrolled()) {
        $navBar.classList.add('nav__bar--fade-in-scroll');
      } else {
        $navBar.classList.remove('nav__bar--fade-in-scroll');
      }
    });

    calculateHeaderHeight(block);
		resizeDebouncer(() => calculateHeaderHeight(block), 250, true);
    
  } catch (error) {
    console.error(error);
  }
}
